import { Button, Card, DatePicker, Divider, Input, Progress, Slider, Spin, Switch } from "antd";
import React, { useState } from "react";
import { utils } from "ethers";
import { SyncOutlined } from "@ant-design/icons";
import { Address, AddressInput, Balance, Events } from "../components";
import { useContractReader } from "eth-hooks";
import { useEffect } from "react";
import erc721Abi from "../abi/ERC721.json";
import { isAddress } from "ethers/lib/utils";

const { ethers } = require("ethers");

export default function PoolFactory({ mainnetProvider, signer, tx, contracts, updatePools }) {
  const [asset, setAsset] = useState("");
  const [isTxPending, setIsTxPending] = useState(false);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");

  useEffect(() => {
    if (isAddress(asset)) {
      const contract = new ethers.Contract(asset, erc721Abi, mainnetProvider);
      contract.name().then(name => {
        setName(name);
      });
      contract.symbol().then(symbol => {
        setSymbol(symbol);
      });
    } else {
      setName("");
      setSymbol("");
    }
  }, [asset]);

  return (
    <div>
      {/*
        ⚙️ Here is an example UI that displays and sets the purpose in your smart contract:
      */}
      <div style={{ border: "1px solid #cccccc", padding: 16, width: 400, margin: "auto", marginTop: 64 }}>
        <h2>Deploy New Pool:</h2>
        <Divider />
        <div style={{ margin: 8 }}>
          <AddressInput
            autoFocus
            ensProvider={mainnetProvider}
            placeholder="NFT Contract Address"
            value={asset}
            onChange={setAsset}
          />
          {/* <Input
            placeholder="asset"
            onChange={e => {
              setAsset(e.target.value);
            }}
          /> */}
          <Button
            style={{ marginTop: 8 }}
            disabled={isTxPending}
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              setIsTxPending(true);
              const result = tx(contracts.PoolFactory.createPool(asset), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
              setIsTxPending(false);
              await updatePools();
            }}
          >
            Deploy Pool
          </Button>
        </div>
        {name && symbol && (
          <Card style={{ width: 300, height: 65, textAlign: "center", margin: "0 auto", marginTop: "20px" }}>
            <p>
              {name} ({symbol})
            </p>
          </Card>
        )}
      </div>
    </div>
  );
}
