import { Button, Card, DatePicker, Divider, Input, Progress, Slider, Spin, Switch, Select, Tabs, Popover } from "antd";
import React, { useState } from "react";
import { utils } from "ethers";
import { SyncOutlined } from "@ant-design/icons";
import erc721Abi from "../abi/ERC721.json";
import poolAbi from "../abi/Pool.json";
import { Address, AddressInput, Balance, Events } from "../components";
import { useEffect } from "react";
import { signERC2612Permit } from "./util";
import { useCallback } from "react";
import axios from "axios";

import { getDatabase, ref, onValue } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA_LQMoklwQ4rilV7nLiaEIpCuYTWzLS4k",
  authDomain: "wnft-development.firebaseapp.com",
  databaseURL: "https://wnft-development-default-rtdb.firebaseio.com",
  projectId: "wnft-development",
  storageBucket: "wnft-development.appspot.com",
  messagingSenderId: "482303713308",
  appId: "1:482303713308:web:e1662c713ba5d655447fd9",
  measurementId: "G-DS0GPRF2YT",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const Option = Select.Option;
const { ethers } = require("ethers");

export default function Pool({ mainnetProvider, signer, tx, contracts, pools, userAddress }) {
  const [isTxPending, setIsTxPending] = useState(false);
  const [contract, setContract] = useState();
  const [pool, setPool] = useState();
  // const [owner, setOwner] = useState();
  const [oldNft, setOldNft] = useState();
  const [nft, setNft] = useState();
  // const [held, setHeld] = useState([]);
  const [approval, setApproval] = useState(false);
  const [approvalErc20, setApprovalErc20] = useState(false);
  const [balanceErc20, setBalanceErc20] = useState(0);
  const [selectedMint, setSelectedMint] = useState([]);
  const [selectedRedeem, setSelectedRedeem] = useState([]);
  const [poolNFTs, setPoolNFTs] = useState([]);
  const [userNFTs, setUserNFTs] = useState([]);

  // const pool_ref = ref(db, `/pools/${contract}`);
  // console.log("pool_ref", `/pools/${contract}`);
  // const [poolSnapshot, loading, error] = useList(pool_ref);
  // const user_ref = ref(db, `/user/${userAddress}/${nft}`);
  // console.log("user_ref", `/user/${userAddress}/${nft}`);
  // const [userSnapshot, user_loading, user_error] = useList(user_ref);

  useEffect(
    () =>
      (async () => {
        let localNFT;
        pools
          .filter(p => p.value === contract)
          .map(p => {
            setNft(p.asset);
            localNFT = p.asset;
          });

        if (contract) {
          const pool_ref = ref(db, `/pools/${contract}`);
          onValue(
            pool_ref,
            snapshot => {
              const data = snapshot.val();
              // console.log("pool_ref", data);
              if (data) {
                setPoolNFTs(data);
              } else {
                setPoolNFTs({});
              }
            },
            {
              onlyOnce: false,
            },
          );
        }

        if (userAddress && localNFT) {
          const user_ref = ref(db, `/user/${userAddress}/${localNFT}`);
          onValue(
            user_ref,
            snapshot => {
              const data = snapshot.val();
              // console.log("user_ref", data);
              if (data) {
                setUserNFTs(data);
              } else {
                setUserNFTs({});
              }
            },
            {
              onlyOnce: false,
            },
          );
        }

        // console.log("🤝🤝🤝🤝🤝🤝calling refresh", contract, localNFT, userAddress);
        if (contract && localNFT && userAddress) {
          // console.log("🅱️🅱️🅱️🅱️🅱️🅱️🅱️🅱️🅱️🅱️calling refresh", contract, localNFT, userAddress);
          await axios.get(
            `https://us-central1-wnft-development.cloudfunctions.net/refreshData?pool_address=${contract}&nft_address=${localNFT}&user_address=${userAddress}`,
          );
        }

        if (utils.isAddress(contract)) {
          const pool0 = new ethers.Contract(contract, poolAbi, signer);
          if (!!pool0.address) {
            const asset = await pool0.asset();
            const nft0 = new ethers.Contract(asset, erc721Abi, signer);
            setPool(pool0);
            setOldNft(nft0);
            setSelectedMint([]);
            setSelectedRedeem([]);
            setApproval(await nft0.isApprovedForAll(userAddress, contract));
            setApprovalErc20((await pool0.allowance(userAddress, contract)).gt(0));
            setBalanceErc20(await pool0.balanceOf(userAddress));
          }
        }
      })(),
    [contract, userAddress],
  );

  const updateTokens = useCallback(
    async function () {
      // console.log("🅱️🅱️🅱️contract", contract);

      if ((utils.isAddress(contract), !!oldNft?.address)) {
        const pool0 = new ethers.Contract(contract, poolAbi, signer);
        // const symbol = await nft.symbol();
        // const owner = await signer.getAddress();
        // setOwner(owner);

        setBalanceErc20(await pool0.balanceOf(userAddress));
        await axios.get(
          `https://us-central1-wnft-development.cloudfunctions.net/refreshPool?pool_address=${contract}&nft_address=${nft}`,
        );
        await axios.get(
          `https://us-central1-wnft-development.cloudfunctions.net/refreshUserNFTs?user_address=${userAddress}&nft_address=${nft}`,
        );
        // const holdingsLen = Number(await pool0.holdingsLength());
        // const temp0 = [];
        // if (refreshOnce !== contract) {
        //   setRefreshOnce(contract);
        //   await axios.get(
        //     `https://us-central1-wnft-development.cloudfunctions.net/refreshData?pool_address=${contract}&nft_address=${nft.address}&user_address=${owner}`,
        //   );
        // }

        // const { data: nft0 } = await axios.get(
        //   `https://us-central1-meltandforge.cloudfunctions.net/getNFTsForOwner?wallet_address=${contract}&contract_address=${nft.address}`,
        // );

        // const hh = nft0
        //   .filter(n => n.contract.address.toLowerCase() === nft.address.toLowerCase())
        //   .map(nft => {
        //     return {
        //       label: `${nft.contract.symbol} #${nft.tokenId}`,
        //       value: `${nft.tokenId}`,
        //       key: `${nft.tokenId}`,
        //       thumbnail: `${nft.media["0"].thumbnail}`,
        //     };
        //   });
        // setHoldings(hh);

        // const { data: nfts } = await axios.get(
        //   `https://us-central1-wnft-development.cloudfunctions.net/getNFTsForOwner?wallet_address=${owner}&contract_address=${nft.address}`,
        // );
        // const h = nfts
        //   .filter(n => n.contract.address.toLowerCase() === nft.address.toLowerCase())
        //   .map(nft => {
        //     return {
        //       label: `${nft.contract.symbol} #${nft.tokenId}`,
        //       value: `${nft.tokenId}`,
        //       thumbnail: `${nft.media["0"].thumbnail}`,
        //     };
        //   });
        // console.log(h);
        // setHeld(h);
      }
    },
    [contract],
  );

  // useEffect(
  //   () =>
  //     (async () => {
  //       if (contract && nft?.address && owner) {
  //         if (lastContract !== contract || lastOwner !== owner || lastNft !== nft?.address) {
  //           await axios.get(
  //             `https://us-central1-wnft-development.cloudfunctions.net/refreshData?pool_address=${contract}&nft_address=${nft?.address}&user_address=${owner}`,
  //           );
  //           setLastContract(contract);
  //           setLastOwner(owner);
  //           setLastNft(nft?.address);
  //         }
  //       }
  //     })(),
  //   [contract, owner, nft?.address],
  // );

  useEffect(() => {
    updateTokens();
  }, [updateTokens]);

  const addTokenMetamask = async (tokenAddress, tokenSymbol) => {
    const tokenDecimals = 18;
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const symbol = pools
    .filter(p => p.value === contract)[0]
    ?.label.split(" (")[1]
    .split(")")[0];

  return (
    <div>
      {/*
        ⚙️ Here is an example UI that displays and sets the purpose in your smart contract:
      */}
      <div style={{ border: "1px solid #cccccc", padding: 16, width: 400, margin: "auto", marginTop: 30 }}>
        <Select
          style={{
            padding: 5,
            margin: "auto",
            marginBottom: 12,
            width: "100%",
          }}
          defaultValue={contract}
          options={pools}
          placeholder="Select NFT Pool"
          onChange={setContract}
        />
        <Divider />

        {!!pool && !!nft && (
          <>
            <div style={{ margin: 8 }}>
              <h4>{pools.filter(p => p.value === contract)[0]?.label.split(" (")[0]}</h4>
              <div>
                <button onClick={() => addTokenMetamask(pools.filter(p => p.value === contract)[0]?.value, symbol)}>
                  Add {symbol} to Metamask
                </button>
                <div>
                  <a href={"https://etherscan.io/token/" + pool.address} target="_blank" rel="noreferrer">
                    View {symbol} on Etherscan
                  </a>
                </div>
                <div>
                  <a href={"https://info.uniswap.org/#/tokens/" + pool.address} target="_blank" rel="noreferrer">
                    ${symbol} Uniswap Markets
                  </a>
                </div>
              </div>
              {Object.values(poolNFTs).length} {Object.values(poolNFTs).length == 1 ? "NFT in pool" : "NFTs in pool"}
              <br></br>
              <a href={"https://opensea.io/" + pool.address} target="_blank" rel="noreferrer">
                View Pool on OpenSea
              </a>
              <br></br>
              <a href={"https://blur.io/" + pool.address} target="_blank" rel="noreferrer">
                View Pool on Blur
              </a>
            </div>
            <Divider />

            <div style={{ margin: 8 }}>
              <h4>Deposit</h4>
              <Select
                style={{
                  padding: 5,
                  margin: "auto",
                  marginBottom: 12,

                  width: "100%",
                }}
                mode="multiple"
                disabled={isTxPending || !approval || Object.values(userNFTs).length == 0}
                placeholder="Select NFTs to deposit"
                allowClear
                value={selectedMint}
                placement="bottomRight"
                optionLabelProp="label"
                onChange={e => {
                  setSelectedMint(e);
                }}
              >
                {Object.values(userNFTs).map(uNFT => (
                  <Option
                    value={uNFT.id}
                    label={uNFT.label}
                    key={uNFT.id}
                    style={{ margin: "auto auto", textAlign: "center", width: "100%", fontSize: "12px" }}
                  >
                    <div style={{ display: "inline-block", justifyContent: "center", width: "80px" }}>
                      <img style={{ margin: "auto auto", width: "100%" }} src={uNFT.thumbnail} alt={uNFT.label}></img>
                    </div>
                    <span style={{ margin: "auto auto", marginLeft: "32px", fontSize: "15px", fontWeight: "bold" }}>
                      {uNFT.label}
                    </span>
                  </Option>
                ))}
              </Select>

              {!approval ? (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending || Object.values(userNFTs).length == 0}
                  onClick={async () => {
                    setIsTxPending(true);
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(oldNft.setApprovalForAll(pool.address, true), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setIsTxPending(false);
                    setApproval(true);
                  }}
                >
                  {/* {console.log("userNFTs.length1Ⓜ️", userNFTs.length)} */}
                  {Object.values(userNFTs).length == 0 ? "No NFTs to Deposit" : "Approve Pool Deposits"}
                </Button>
              ) : (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending || Object.values(userNFTs).length == 0 || selectedMint.length == 0}
                  onClick={async () => {
                    setIsTxPending(true);
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(pool.deposit(selectedMint), async update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setSelectedMint([]);
                    setIsTxPending(false);
                    await updateTokens();
                  }}
                >
                  {/* {console.log("userNFTs.length", userNFTs.length)} */}
                  {Object.values(userNFTs).length == 0 ? "No NFTs to Deposit" : "Deposit NFTs"}
                </Button>
              )}
            </div>

            <Divider />

            <div style={{ margin: 8 }}>
              <h4>Withdraw</h4>
              <Select
                style={{
                  padding: 5,
                  margin: "auto",
                  marginBottom: 12,

                  width: "100%",
                }}
                mode="multiple"
                disabled={isTxPending || !approvalErc20 || balanceErc20 == 0}
                placeholder="Select NFTs to withdraw"
                allowClear
                value={selectedRedeem}
                placement="bottomRight"
                optionLabelProp="label"
                onChange={e => {
                  if (balanceErc20.gte(`${e.length}000000000000000000`)) {
                    setSelectedRedeem(e);
                  }
                }}
              >
                {/* {!loading && snapshots && ( */}
                {Object.values(poolNFTs).map(pNFT => (
                  <Option
                    value={pNFT.id}
                    label={pNFT.label}
                    key={pNFT.id}
                    style={{ margin: "auto auto", textAlign: "center", width: "100%", fontSize: "12px" }}
                  >
                    <div style={{ display: "inline-block", justifyContent: "center", width: "80px" }}>
                      <img style={{ margin: "auto auto", width: "100%" }} src={pNFT.thumbnail} alt={pNFT.label}></img>
                    </div>
                    <span style={{ margin: "auto auto", marginLeft: "32px", fontSize: "15px", fontWeight: "bold" }}>
                      {pNFT.label}
                    </span>
                  </Option>
                  // <React.Fragment key={v.val().id}>{v.val().id}, </React.Fragment>
                ))}
                {/* )} */}
                {/* {holdings.map(nft => (
                  <Option
                    value={nft.value}
                    label={nft.label}
                    style={{ margin: "auto auto", textAlign: "center", width: "100%", fontSize: "12px" }}
                  >
                    <div style={{ display: "inline-block", justifyContent: "center", width: "80px" }}>
                      <img style={{ margin: "auto auto", width: "100%" }} src={nft.thumbnail} alt={nft.label}></img>
                    </div>
                    <span style={{ margin: "auto auto", marginLeft: "32px", fontSize: "15px", fontWeight: "bold" }}>
                      {nft.label}
                    </span>
                  </Option>
                ))} */}
              </Select>

              {!approvalErc20 ? (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending || balanceErc20 == 0}
                  onClick={async () => {
                    setIsTxPending(true);
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    // TODO: fix
                    // const deadline = (await mainnetProvider.getBlock("latest")).timestamp + 100
                    // const signature = signERC2612Permit({
                    //   provider: mainnetProvider,
                    //   verifyingContract: pool.address,
                    //   ownerAddress: await signer.getAddress(),
                    //   spenderAddress: pool.address,
                    //   amount: `${selectedRedeem.length}000000000000000000`,
                    //   deadline: deadline,
                    // });
                    const result = tx(pool.approve(pool.address, ethers.constants.MaxUint256), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setIsTxPending(false);
                    setApprovalErc20(true);
                  }}
                >
                  {balanceErc20 == 0 ? "No Pool Tokens" : "Approve Withdrawals"}
                </Button>
              ) : (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending || balanceErc20 == 0 || selectedRedeem.length == 0}
                  onClick={async () => {
                    setIsTxPending(true);
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(pool.withdraw(selectedRedeem), async update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setSelectedRedeem([]);
                    setIsTxPending(false);
                    await updateTokens();
                  }}
                >
                  {balanceErc20 == 0 ? "No Pool Tokens" : "Withdraw NFTs"}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
