import { Button, Card, DatePicker, Divider, Input, Progress, Slider, Spin, Switch, Select } from "antd";
import React, { useState } from "react";
import { utils } from "ethers";
import { SyncOutlined } from "@ant-design/icons";
import erc721Abi from "../abi/ERC721.json";
import vaultAbi from "../abi/Vault.json";

import { Address, AddressInput, Balance, Events } from "../components";
import { useEffect } from "react";
import { useCallback } from "react";

const { ethers } = require("ethers");

export default function Vault({ mainnetProvider, signer, tx, contracts, vaults }) {
  const [isTxPending, setIsTxPending] = useState(false);
  const [contract, setContract] = useState();
  const [vault, setVault] = useState();
  const [nft, setNft] = useState();
  const [holdings, setHoldings] = useState([]);
  const [held, setHeld] = useState([]);
  const [approval, setApproval] = useState(false);
  const [approvalErc20, setApprovalErc20] = useState(false);
  const [selectedMint, setSelectedMint] = useState([]);
  const [selectedRedeem, setSelectedRedeem] = useState([]);

  useEffect(
    () =>
      (async () => {
        if (utils.isAddress(contract)) {
          const vault0 = new ethers.Contract(contract, vaultAbi, signer);
          if (!!vault0.address) {
            const asset = await vault0.asset();
            const nft0 = new ethers.Contract(asset, erc721Abi, signer);
            setVault(vault0);
            setNft(nft0);
            const owner = await signer.getAddress();
            setApproval(await nft0.isApprovedForAll(owner, contract));
            setApprovalErc20((await vault0.allowance(owner, contract)).gt(0));

            await updateTokens();
          }
        }
      })(),
    [contract],
  );

  const updateTokens = useCallback(
    async function () {
      if ((utils.isAddress(contract), !!nft?.address)) {
        const vault0 = new ethers.Contract(contract, vaultAbi, signer);
        const symbol = await nft.symbol();
        const owner = await signer.getAddress();
        const holdingsLen = Number(await vault0.holdingsLength(owner));
        const temp0 = [];
        for (let i = 0; i < holdingsLen; i++) {
          const id = await vault0.holdingAt(owner, i);
          temp0.push({
            label: `${symbol} #${id}`,
            value: `${id}`,
          });
        }
        setHoldings(temp0);

        const balance = Number(await nft.balanceOf(owner));
        const temp = [];
        for (let i = 0; i < balance; i++) {
          const id = await nft.tokenOfOwnerByIndex(owner, i);
          temp.push({
            label: `${symbol} #${id}`,
            value: `${id}`,
          });
        }
        setHeld(temp);
      }
    },
    [contract, nft?.address],
  );

  useEffect(() => {
    updateTokens();
  }, [updateTokens]);

  return (
    <div>
      {/*
        ⚙️ Here is an example UI that displays and sets the purpose in your smart contract:
      */}
      <div style={{ border: "1px solid #cccccc", padding: 16, width: 400, margin: "auto", marginTop: 64 }}>
        <h2>Vault:</h2>
        <Select
          style={{
            padding: 5,
            margin: "auto",
            marginBottom: 12,

            width: "100%",
          }}
          defaultValue={contract}
          options={vaults}
          placeholder="Enter vault contract address here"
          onChange={setContract}
        />
        <Divider />

        {!!vault && !!nft && (
          <>
            <div style={{ margin: 8 }}>
              <h4>NFTs in your Vault</h4>
              {holdings
                .map(h => h.label)
                .map(l => (
                  <span>{l}&nbsp;&nbsp;</span>
                ))}
            </div>

            <Divider />

            <div style={{ margin: 8 }}>
              <h4>Vault NFT(s)</h4>
              <Select
                style={{
                  padding: 5,
                  margin: "auto",
                  marginBottom: 12,

                  width: "100%",
                }}
                mode="multiple"
                disabled={isTxPending || !approval}
                placeholder="Select NFT(s) to add to vault"
                allowClear
                options={held}
                value={selectedMint}
                onChange={e => {
                  console.log(e);
                  setSelectedMint(e);
                }}
              />

              {!approval ? (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending}
                  onClick={async () => {
                    setIsTxPending(true);
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(nft.setApprovalForAll(vault.address, true), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setIsTxPending(false);
                    setApproval(true);
                  }}
                >
                  Approve Vault to Spend NFTs
                </Button>
              ) : (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending}
                  onClick={async () => {
                    setIsTxPending(true);
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    const result = tx(vault.deposit(selectedMint, await signer.getAddress()), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setSelectedMint([]);
                    setIsTxPending(false);
                    await updateTokens();
                  }}
                >
                  Add NFT(s) to Vault
                </Button>
              )}
            </div>

            <Divider />

            <div style={{ margin: 8 }}>
              <h4>Unvault NFT(s)</h4>
              <Select
                style={{
                  padding: 5,
                  margin: "auto",
                  marginBottom: 12,

                  width: "100%",
                }}
                mode="multiple"
                disabled={isTxPending || !approvalErc20}
                placeholder="Select NFT(s) to remove from vault"
                allowClear
                options={holdings}
                value={selectedRedeem}
                onChange={e => {
                  setSelectedRedeem(e);
                }}
              />

              {!approvalErc20 ? (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending}
                  onClick={async () => {
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    setIsTxPending(true);
                    const result = tx(vault.approve(vault.address, ethers.constants.MaxUint256), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setIsTxPending(false);
                    setApprovalErc20(true);
                  }}
                >
                  Approve Vault to Spend your Vaulted NFT ERC20
                </Button>
              ) : (
                <Button
                  style={{ marginTop: 8 }}
                  disabled={isTxPending}
                  onClick={async () => {
                    /* look how you call setPurpose on your contract: */
                    /* notice how you pass a call back for tx updates too */
                    setIsTxPending(true);
                    const result = tx(vault.withdraw(selectedRedeem, await signer.getAddress()), update => {
                      console.log("📡 Transaction Update:", update);
                      if (update && (update.status === "confirmed" || update.status === 1)) {
                        console.log(" 🍾 Transaction " + update.hash + " finished!");
                        console.log(
                          " ⛽️ " +
                            update.gasUsed +
                            "/" +
                            (update.gasLimit || update.gas) +
                            " @ " +
                            parseFloat(update.gasPrice) / 1000000000 +
                            " gwei",
                        );
                      }
                    });
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                    setSelectedRedeem([]);
                    setIsTxPending(false);
                    await updateTokens();
                  }}
                >
                  Remove NFT(s) from Vault
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
