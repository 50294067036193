import { Button, Col, Menu, Row } from "antd";
import { SwapWidget } from "@uniswap/widgets";
import "@uniswap/widgets/dist/fonts.css";

import "antd/dist/antd.css";
import {
  useBalance,
  useContractLoader,
  useContractReader,
  // useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import {
  Account,
  Contract,
  Faucet,
  GasGauge,
  Header,
  Ramp,
  ThemeSwitch,
  NetworkDisplay,
  FaucetHint,
  NetworkSwitch,
} from "./components";
import { NETWORKS, ALCHEMY_KEY } from "./constants";
// contracts
import { getRPCPollTime, Transactor, Web3ModalSetup } from "./helpers";
import { PoolFactory, Pool, Vault } from "./views";
import { useStaticJsonRPC, useGasPrice, useContractConfig } from "./hooks";
import VaultFactory from "./views/VaultFactory";
import Nft from "./views/Nft";
import { Footer } from "antd/lib/layout/layout";

import { getDatabase, ref, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import NftSwap from "./views/NftSwap";

const firebaseConfig = {
  apiKey: "AIzaSyA_LQMoklwQ4rilV7nLiaEIpCuYTWzLS4k",
  authDomain: "wnft-development.firebaseapp.com",
  databaseURL: "https://wnft-development-default-rtdb.firebaseio.com",
  projectId: "wnft-development",
  storageBucket: "wnft-development.appspot.com",
  messagingSenderId: "482303713308",
  appId: "1:482303713308:web:e1662c713ba5d655447fd9",
  measurementId: "G-DS0GPRF2YT",
};

const { ethers } = require("ethers");
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

// 😬 Sorry for all the console logging
const DEBUG = false;
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false; // toggle burner wallet feature
const USE_NETWORK_SELECTOR = false;

const web3Modal = Web3ModalSetup();
const networkOptions = ["mainnet"];
const providers = [`https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`];

function App() {
  const [pools, setPools] = useState([]);
  // const [vaults, setVaults] = useState([]);
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const location = useLocation();

  const targetNetwork = NETWORKS[selectedNetwork];

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);

  const mainnetProvider = useStaticJsonRPC(providers, localProvider);

  // Sensible pollTimes depending on the provider you are using
  const localProviderPollingTime = getRPCPollTime(localProvider);
  const mainnetProviderPollingTime = getRPCPollTime(mainnetProvider);

  if (DEBUG) console.log(`Using ${selectedNetwork} network`);

  // 🛰 providers
  if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(targetNetwork, mainnetProvider, mainnetProviderPollingTime);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "FastGasPrice", localProviderPollingTime);
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address, localProviderPollingTime);

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address, mainnetProviderPollingTime);

  const contractConfig = useContractConfig();

  // const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  // If you want to call a function on a new block
  // useOnBlock(mainnetProvider, () => {
  //   console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  // });

  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader(
    mainnetContracts,
    "DAI",
    "balanceOf",
    ["0x34aA3F359A9D614239015126635CE7732c18fDF3"],
    mainnetProviderPollingTime,
  );

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:", addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("💵 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
      console.log("💵 yourMainnetBalance", yourMainnetBalance ? ethers.utils.formatEther(yourMainnetBalance) : "...");
      console.log("📝 readContracts", readContracts);
      console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      console.log("💵 yourMainnetDAIBalance", myMainnetDAIBalance);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
    myMainnetDAIBalance,
  ]);

  const loadWeb3Modal = useCallback(async () => {
    //const provider = await web3Modal.connect();
    const provider = await web3Modal.requestProvider();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
    //automatically connect if it is a safe app
    const checkSafeApp = async () => {
      if (await web3Modal.isSafeApp()) {
        loadWeb3Modal();
      }
    };
    checkSafeApp();
  }, [loadWeb3Modal]);

  // useEffect(() => {
  //   const path = window.location.href.split("/#/");
  //   const page = path[path.length - 1];
  //   if (page != "welcome") {
  //     loadWeb3Modal();
  //   }
  // }, [window.location.href, loadWeb3Modal]);

  useEffect(() => {
    (async () => {
      const pool_ref = ref(db, `/factory_pools`);
      onValue(
        pool_ref,
        snapshot => {
          const data = snapshot.val();
          // console.log("pool_ref", data);
          if (data) {
            setPools(Object.values(data));
          } else {
            setPools([]);
          }
        },
        {
          onlyOnce: false,
        },
      );
    })();
  }, [readContracts]);

  const updatePools = useCallback(
    async function () {
      const pool_ref = ref(db, `/factory_pools`);
      onValue(
        pool_ref,
        snapshot => {
          const data = snapshot.val();
          // console.log("pool_ref", data);
          if (data) {
            setPools(Object.values(data));
          } else {
            setPools([]);
          }
        },
        {
          onlyOnce: false,
        },
      );
    },
    [readContracts],
  );

  // const updateVaults = useCallback(
  //   async function () {
  //     if (!!readContracts.VaultFactory) {
  //       const [allVaultsLength] = await Promise.all([readContracts.VaultFactory.allVaultsLength()]);
  //       const ERC20ABI = ["function symbol() view returns (string)", "function name() view returns (string)"];

  //       const V = [];
  //       for (let i = 0; i < Number(allVaultsLength); i++) {
  //         const v = await readContracts.VaultFactory.allVaults(i);
  //         const r = new ethers.Contract(v, ERC20ABI, injectedProvider);
  // const name = await r.name?.();
  // const symbol = await r.symbol?.();
  // V.push({
  //   label: `${name} (${symbol})`,
  //   value: v,
  // });
  //       }
  //       // setVaults(V);
  //     }
  //   },
  //   [readContracts],
  // );

  const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name.indexOf("local") !== -1;

  return (
    <div className="App">
      <div style={{ marginBottom: "-70px", minHeight: "100vh" }}>
        {/* ✏️ Edit the header and change the title to your project name */}
        <Header>
          {/* 👨‍💼 Your account is in the top right with a wallet at connect options */}
          <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flex: 1 }}>
              {USE_NETWORK_SELECTOR && (
                <div style={{ marginRight: 20 }}>
                  <NetworkSwitch
                    networkOptions={networkOptions}
                    selectedNetwork={selectedNetwork}
                    setSelectedNetwork={setSelectedNetwork}
                  />
                </div>
              )}
              <Account
                useBurner={USE_BURNER_WALLET}
                address={address}
                localProvider={localProvider}
                userSigner={userSigner}
                mainnetProvider={mainnetProvider}
                price={price}
                web3Modal={web3Modal}
                loadWeb3Modal={loadWeb3Modal}
                logoutOfWeb3Modal={logoutOfWeb3Modal}
                blockExplorer={blockExplorer}
              />
            </div>
          </div>
        </Header>
        {yourLocalBalance.lte(ethers.BigNumber.from("0")) && (
          <FaucetHint localProvider={localProvider} targetNetwork={targetNetwork} address={address} />
        )}
        <NetworkDisplay
          NETWORKCHECK={NETWORKCHECK}
          localChainId={localChainId}
          selectedChainId={selectedChainId}
          targetNetwork={targetNetwork}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
        />
        <Menu style={{ textAlign: "center", marginTop: 20 }} selectedKeys={[location.pathname]} mode="horizontal">
          <Menu.Item key="/welcome">
            <Link to="/welcome">Welcome</Link>
          </Menu.Item>

          <Menu.Item key="/home">
            <Link to="/home">Home</Link>
          </Menu.Item>

          <Menu.Item key="/uniswap">
            <Link to="/uniswap">Uniswap</Link>
          </Menu.Item>

          <Menu.Item key="/nft-swap">
            <Link to="/nft-swap">NFT Swap</Link>
          </Menu.Item>

          {/* <Menu.Item key="/vault">
          <Link to="/vault">Vault</Link>
        </Menu.Item> */}

          {/* <Menu.Item key="/new">
            <Link to="/new">Create Pool</Link>
          </Menu.Item> */}

          {/* <Menu.Item key="/mint-nft">
          <Link to="/mint-nft">Mint Test NFT</Link>
        </Menu.Item> */}
        </Menu>

        <Switch>
          <Route exact path="/" render={() => <Redirect to="/welcome" />} />

          {/* <Route exact path="/mint-nft">
          <Nft mainnetProvider={mainnetProvider} signer={userSigner} tx={tx} contracts={writeContracts} />
        </Route> */}

          <Route exact path="/home">
            {/* pass in any web3 props to this Home component. For example, yourLocalBalance */}
            {/* <div
            style={{
              margin: "0 auto",
              width: "80%",
              maxWidth: "700px",
              marginTop: "30px",
              marginBottom: "0px",
              fontSize: "12px",
            }}
          >
            <span>Pooled NFTs are ERC-20 tokens fully backed 1:1 by specific NFT collections. No Fees.</span>
            <div style={{ marginBottom: "10px" }}></div>
            <span>What can I do with Pooled NFTs?</span>
            <div style={{ marginBottom: "10px" }}></div>
            <span>
              {" "}
              Swap with the pool, fractionalize any NFT, add liquidity to uniswap, buy fractions of a collection on
              Uniswap, arbitrage with Open Sea. Connect NFT projects to DeFi. Read more
              <a href=""> here</a>.
            </span>
          </div> */}
            {!!userSigner ? (
              <div>
                <Pool
                  mainnetProvider={mainnetProvider}
                  signer={userSigner}
                  tx={tx}
                  contracts={writeContracts}
                  pools={pools}
                  userAddress={address}
                />
                <div style={{ marginTop: 36, marginBottom: 0, fontSize: "12px" }}>
                  Pooled NFTs have not been audited and risks always exist with new software.
                </div>
                <div style={{ marginTop: 1, marginBottom: 0, fontSize: "12px" }}>
                  Having trouble? Report any issues in our{" "}
                  <a href="https://discord.com/invite/mhtSRz6" target="_blank" rel="noreferrer">
                    Discord
                  </a>
                  .
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginTop: 50, marginBottom: 12, fontSize: "15px" }}>Connect your wallet to proceed.</div>
                <Button style={{ marginLeft: 8 }} shape="round" onClick={loadWeb3Modal}>
                  Connect Wallet
                </Button>
              </div>
            )}
          </Route>

          <Route exact path="/uniswap">
            <div
              className="Uniswap"
              style={{ marginTop: "70px", width: "100%", display: "flex", justifyContent: "center" }}
            >
              <SwapWidget
                provider={injectedProvider}
                tokenList={
                  "https://raw.githubusercontent.com/hifi-finance/pooled-nft-token-list/main/build/pooled-nft.tokenlist.json"
                }
                // defaultInputTokenAddress={NATIVE}
                // defaultOutputTokenAddress={WBTC}
              />
            </div>
          </Route>

          <Route exact path="/nft-swap">
            {!!userSigner ? (
              <div>
                <NftSwap
                  mainnetProvider={mainnetProvider}
                  signer={userSigner}
                  tx={tx}
                  contracts={writeContracts}
                  pools={pools}
                  userAddress={address}
                />
                <div style={{ marginTop: 36, marginBottom: 0, fontSize: "12px" }}>
                  Pooled NFTs have not been audited and risks always exist with new software.
                </div>
                <div style={{ marginTop: 1, marginBottom: 0, fontSize: "12px" }}>
                  Having trouble? Report any issues in our{" "}
                  <a href="https://discord.com/invite/mhtSRz6" target="_blank" rel="noreferrer">
                    Discord
                  </a>
                  .
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginTop: 50, marginBottom: 12, fontSize: "15px" }}>Connect your wallet to proceed.</div>
                <Button style={{ marginLeft: 8 }} shape="round" onClick={loadWeb3Modal}>
                  Connect Wallet
                </Button>
              </div>
            )}
          </Route>

          <Route exact path="/welcome">
            {/* pass in any web3 props to this Home component. For example, yourLocalBalance */}
            <img
              src="https://firebasestorage.googleapis.com/v0/b/wnft-development.appspot.com/o/public%2FpooledNFT.gif?alt=media&token=8b5bc59f-f3ec-4e61-b6bc-639d0e984013"
              alt=""
              style={{ width: "80%", maxWidth: "700px", marginBottom: "20px" }}
            />
            <h1>What are Pooled NFTs?</h1>
            <div
              style={{
                margin: "0 auto",
                width: "80%",
                maxWidth: "700px",
                marginBottom: "20px",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              <div>
                As the name implies, Pooled NFTs are simply NFTs pooled together inside a smart contract. For each NFT
                added to the pool, users recieve an equal number of Pool Tokens that represents their ownership of the
                pool. These Pool Tokens are standard ERC-20 tokens you see being used throughout Ethereum and DeFi
                today. Pool Tokens are always backed one to one by the NFTs in the pool. To get NFTs out of the pool,
                users exchange one Pool Token for each NFT they want to remove from the pool.
              </div>
              <br></br>
              <div>
                NFT Pools are collection-specific, meaning each NFT collection has a dedicated NFT Pool and only NFTs
                from that collection can be added to its pool. Anyone can create new NFT Pools.
              </div>
              <br></br>
              <div>
                Currently, Pooled NFTs support ERC-721 NFTs. Pooled NFT smart contracts do not contain any admin
                permissions and are free to use with no added fees. Users just pay gas for transactions.
              </div>
              <br></br>
            </div>
            <h2>Official Links</h2>
            <div
              style={{
                margin: "0 auto",
                width: "80%",
                maxWidth: "700px",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              <div>
                <a href="https://twitter.com/poolednft">@PooledNFT</a>
              </div>
              <div>
                <a href="https://github.com/hifi-finance/pooled-nft">GitHub Repo (Source Code)</a>
              </div>
              <div>
                <a href="https://discord.gg/mhtSRz6">Hifi Discord</a>
              </div>
              <div>
                <a href="https://etherscan.io/address/0xb67dc4B5A296C3068E8eEc16f02CdaE4c9A255e5">
                  ERC-721 Pool Factory - Smart Contract (Mainnet)
                </a>
              </div>
              <div>
                <a href="https://etherscan.io/address/0xd1557677ffe0a6741a652d53b86c63c0b2f503aa">
                  Example Pool Token (Pawn Bots - Mainnet)
                </a>
              </div>
              <div>
                <a href="https://goerli.etherscan.io/address/0xC924C92e49996066363292dB5B31A79a4f658753#code">
                  ERC-721 Pool Factory - Smart Contract (Goerli Testnet)
                </a>
              </div>
              <div>
                <a href="https://goerli.etherscan.io/address/0x6548606B21C1DE801dbD5497ce9796AfF3E48eE3">
                  Example Pool Token (Pawn Bots - Goerli Testnet)
                </a>
              </div>
            </div>
            <br></br>

            <h2>Community Resources</h2>
            <div
              style={{
                margin: "0 auto",
                width: "80%",
                maxWidth: "700px",
                fontSize: "16px",
                textAlign: "left",
              }}
            >
              <div>
                <a href="https://www.poolmynft.xyz/">Pool My NFT</a> - Open Source Front-end by @bijoydoteth -
                <a> Source Code</a>
              </div>
              <div>
                <a href="https://blog.hifi.finance/pooled-nfts-passive-income-for-any-nft-c35fe80f9386">
                  Hifi Announcement Blog Post - Pooled NFTs: Passive Income For Any NFT
                </a>
              </div>
              <div>
                <a href="https://twitter.com/0xjustadev/status/1630303405762662404?s=20">
                  @0xjustadev's Twitter Thread
                </a>
              </div>
              <div>
                <a href="https://www.reddit.com/r/Mainbrain_/comments/1192cov/how_to_use_hifis_pooled_nft_protocol_with_the/">
                  Mainbrain's Reddit Tutorial
                </a>
              </div>
              <div>
                <a href="https://www.youtube.com/watch?v=i9hiH6Azpqg&feature=youtu.be&t=220">
                  TaeKwonKrypto's Video Walk Through
                </a>
              </div>
            </div>
            <br></br>

            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/i9hiH6Azpqg?start=220"
              title="Pooled NFT Tutorial"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </Route>

          {/* <Route exact path="/vault">
          <Vault
            mainnetProvider={mainnetProvider}
            signer={userSigner}
            tx={tx}
            contracts={writeContracts}
            vaults={vaults}
          />
        </Route> */}
        </Switch>
        {/* <ThemeSwitch /> */}

        <div style={{ position: "fixed", textAlign: "left", left: 0, bottom: 20, padding: 10 }}>
          <Row align="middle" gutter={[4, 4]}>
            <Col span={24}>
              {
                /*  if the local provider has a signer, let's show the faucet:  */
                faucetAvailable ? (
                  <Faucet localProvider={localProvider} price={price} ensProvider={mainnetProvider} />
                ) : (
                  ""
                )
              }
            </Col>
          </Row>
        </div>
      </div>
      <Footer style={{ display: "block", width: "100%", height: "70px" }}>
        Pooled NFT by{" "}
        <a href="https://hifi.finance" target="_blank" rel="noreferrer">
          Hifi Finance
        </a>
      </Footer>
    </div>
  );
}

export default App;
