import { Button, Card, DatePicker, Divider, Input, Progress, Slider, Spin, Switch, Select } from "antd";
import React, { useState } from "react";
import { utils } from "ethers";
import { SyncOutlined } from "@ant-design/icons";
import erc721Abi from "../abi/ERC721.json";

import { Address, AddressInput, Balance, Events } from "../components";
import { useEffect } from "react";
import NFTs from "./TestNFTs.json";
import { useCallback } from "react";
const { ethers } = require("ethers");

export default function Nft({ mainnetProvider, signer, tx, contracts }) {
  const [contract, setContract] = useState(NFTs[0].value);
  const [totalSupply, setTotalSupply] = useState(0);
  const [held, setHeld] = useState([]);
  const [isTxPending, setIsTxPending] = useState(false);

  const updateTokens = useCallback(
    async function () {
      if (utils.isAddress(contract) && !!signer) {
        const nft = new ethers.Contract(contract, erc721Abi, signer);
        setTotalSupply(await nft.totalSupply());
        const owner = await signer.getAddress();
        const balance = Number(await nft.balanceOf(owner));
        const symbol = await nft.symbol();
        const temp = [];
        for (let i = 0; i < balance; i++) {
          const id = await nft.tokenOfOwnerByIndex(owner, i);
          temp.push({
            label: `${symbol} #${id}`,
            value: `${id}`,
          });
        }
        setHeld(temp);
      }
    },
    [contract, signer],
  );

  useEffect(() => {
    updateTokens();
  }, [updateTokens]);

  return (
    <div>
      {/*
        ⚙️ Here is an example UI that displays and sets the purpose in your smart contract:
      */}
      <div style={{ border: "1px solid #cccccc", padding: 16, width: 400, margin: "auto", marginTop: 64 }}>
        <h2>NFT Contract:</h2>
        <Address address={contract} ensProvider={mainnetProvider} fontSize={16} />
        <Select
          style={{
            padding: 5,
            margin: "auto",
            marginBottom: 12,

            width: "100%",
          }}
          defaultValue={contract}
          options={NFTs}
          placeholder="Enter test NFT contract address here"
          onChange={e => setContract(e)}
        />
        <Divider />
        <div style={{ margin: 8 }}>
          <h4>NFTs you hold in wallet</h4>
          <div>
            {held.map(h => (
              <>{h.label}&nbsp;&nbsp;</>
            ))}
          </div>
          {/* <Input
            placeholder="asset"
            onChange={e => {
              setAsset(e.target.value);
            }}
          /> */}
          <Button
            style={{ marginTop: 8 }}
            disabled={isTxPending}
            onClick={async () => {
              /* look how you call setPurpose on your contract: */
              /* notice how you pass a call back for tx updates too */
              setIsTxPending(true);
              const nft = new ethers.Contract(contract, erc721Abi, signer);
              const result = tx(nft.__godMode_mint(await signer.getAddress(), totalSupply), update => {
                console.log("📡 Transaction Update:", update);
                if (update && (update.status === "confirmed" || update.status === 1)) {
                  console.log(" 🍾 Transaction " + update.hash + " finished!");
                  console.log(
                    " ⛽️ " +
                      update.gasUsed +
                      "/" +
                      (update.gasLimit || update.gas) +
                      " @ " +
                      parseFloat(update.gasPrice) / 1000000000 +
                      " gwei",
                  );
                }
              });
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
              setIsTxPending(false);
              await updateTokens();
            }}
          >
            Mint NFT
          </Button>
        </div>
      </div>
    </div>
  );
}
